import React from 'react';
import Alert from '@mui/material/Alert';
import DisplayWarning from './DisplayWarning';
import { clipCodes } from '../helpers/orderStatusHelperClip';
import { orderStatusStyle } from '../lib/styles';

const DisplayOrderStatusClip = ({ order }) => {
    const { payment_status, payment_status_detail } = order;

    if (payment_status) {
        try {
            switch (payment_status) {
                case 'approved':
                    return (
                        <Alert
                            severity="success"
                            sx={{ width: '100%', overflow: 'hidden' }}
                        >
                            {`${
                                clipCodes[payment_status].find(
                                    (status_detail) =>
                                        status_detail.code ===
                                        payment_status_detail.code,
                                ).message
                            }`}
                        </Alert>
                    );
                case 'rejected':
                    return (
                        <Alert severity="error" sx={orderStatusStyle}>
                            {`${
                                clipCodes[payment_status].find(
                                    (status_detail) =>
                                        status_detail.code ===
                                        payment_status_detail.code,
                                ).message
                            }`}
                        </Alert>
                    );
                case 'error':
                    return (
                        <Alert severity="error" sx={orderStatusStyle}>
                            {`Hubo un error con tu pago. ${payment_status_detail[0]}`}
                        </Alert>
                    );
                default:
                    return <DisplayWarning />;
            }
        } catch (e) {
            return <DisplayWarning />;
        }
    }
    return null;
};
export { DisplayOrderStatusClip };
