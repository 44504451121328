import React, { useState, useEffect } from 'react';
import { isNull, isUndefined } from 'lodash';
import { payment_statuses } from '../helpers/paymentHelper';
import Alert from '@mui/material/Alert';
import DisplayWarning from './DisplayWarning';

const DisplayOrderStatusMercadopago = ({ order }) => {
    const [paid, setPaid] = useState(!isNull(order.date_paid)); // TODO use status instead fix for Mercadopago
    useEffect(() => {
        setPaid(!isNull(order.date_paid));
    }, [order.date_paid]);
    if (order.order_id) {
        try {
            const { payment_status, payment_status_detail } = order;
            if (
                !isUndefined(payment_status) &&
                !isUndefined(payment_status_detail)
            ) {
                return paid || order.payment_status !== 'rejected' ? (
                    <Alert severity="success">
                        {`${payment_statuses[payment_status][payment_status_detail]['Descripción']}`}
                    </Alert>
                ) : (
                    <Alert severity="error">
                        {`${payment_statuses[payment_status][payment_status_detail]['Descripción']}`}
                    </Alert>
                );
            } else {
                return <DisplayWarning />;
            }
        } catch (e) {
            return <DisplayWarning />;
        }
    }
    return null;
};
export { DisplayOrderStatusMercadopago };
