import React, { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { MAX_UNIT_QUANTITY, MIN_UNIT_QUANTITY } from '../lib/limits';

export default function Product({ item, order, setOrder }) {
    const [itemInOrder, setItemInOrder] = useState({
        id: item.id,
        name: item.name,
        price: Number.parseFloat(item.price),
        quantity:
            Number.parseInt(
                order.items.find((element) => element.id === item.id)?.quantity,
            ) | 0,
    });

    const buildNewOrder = (items) => {
        return {
            ...order,
            ...{
                items: items,
                amount: amountCalculus(items),
            },
        };
    };

    const updateOrder = (updatedItem) => {
        setItemInOrder(updatedItem);
        const { items } = order;
        if (items.length === 0) {
            items.push(updatedItem);
            setOrder(buildNewOrder(items));
        } else {
            const idx = items.findIndex(
                (element) => element.id === updatedItem.id,
            );
            if (idx === -1) {
                items.push(updatedItem);
                setOrder(buildNewOrder(items));
            } else {
                if (updatedItem.quantity < 1) {
                    setOrder(
                        buildNewOrder(
                            items.slice(0, idx).concat(items.slice(idx + 1)),
                        ),
                    );
                } else {
                    items[idx] = updatedItem;
                    setOrder(buildNewOrder(items));
                }
            }
        }
    };

    const updateState = (e) => {
        const { value } = e.target;
        if (
            !isNaN(value) &&
            Number.parseInt(value) <= MAX_UNIT_QUANTITY &&
            Number.parseInt(value) > -1
        ) {
            const updatedItem = {
                ...itemInOrder,
                ...{ quantity: Number.parseInt(value) },
            };
            updateOrder(updatedItem);
        }
    };

    const updateOnButton = (b) => {
        let updatedItem = { ...itemInOrder };
        if (isNaN(updatedItem.quantity)) {
            updatedItem = {
                ...itemInOrder,
                ...{ quantity: MIN_UNIT_QUANTITY },
            };
        } else {
            if (b === '-') {
                if (itemInOrder.quantity > MIN_UNIT_QUANTITY) {
                    updatedItem = {
                        ...itemInOrder,
                        ...{ quantity: itemInOrder.quantity - 1 },
                    };
                }
            }
            if (b === '+') {
                if (
                    itemInOrder.quantity < MAX_UNIT_QUANTITY ||
                    itemInOrder.quantity === MIN_UNIT_QUANTITY
                ) {
                    updatedItem = {
                        ...itemInOrder,
                        ...{ quantity: itemInOrder.quantity + 1 },
                    };
                }
            }
        }
        updateOrder(updatedItem);
    };

    const amountCalculus = (items) => {
        let subTotal = 0.0;
        items.forEach((item) => {
            if (!isNaN(item.quantity)) {
                subTotal +=
                    Number.parseFloat(item.price) *
                    Number.parseFloat(item.quantity);
            }
        });
        return subTotal;
    };

    return (
        <TableRow
            key={`item-${item.id}`}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell>
                <Typography variant="subtitle2">{`${item.name}`}</Typography>
                <Typography variant="body2">{`$${item.price}`}</Typography>
            </TableCell>
            <TableCell align="right">
                <IconButton sx={{ p: 0.2 }} onClick={() => updateOnButton('-')}>
                    <RemoveCircleIcon fontSize="small" color="primary" />
                </IconButton>
                <input
                    type="number"
                    id={item.id}
                    name={item.id}
                    value={itemInOrder.quantity}
                    min={MIN_UNIT_QUANTITY}
                    max={MAX_UNIT_QUANTITY}
                    onChange={updateState}
                    style={{ width: '45px' }}
                />
                <IconButton sx={{ p: 0.2 }} onClick={() => updateOnButton('+')}>
                    <AddCircleIcon fontSize="small" color="primary" />
                </IconButton>
            </TableCell>
        </TableRow>
    );
}
