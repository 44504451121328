import { logDebug } from '../lib/logger';

const apiBase = process.env.REACT_APP_RF_API;

/**
 * Makes an API call.
 * @param {string} url - The URL to make the API call to.
 * @param {string} method - The HTTP method to use for the API call.
 * @param {Object} payload - The payload to send with the API call.
 * @returns {Promise<Array|Object>} - A promise that resolves to the response data from the API call.
 */
async function apiCall(url, method, payload) {
    const options = {
        credentials: 'include',
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': await getCsrfToken(),
        },
    };
    if (payload) {
        options.body = JSON.stringify(payload);
    }
    try {
        const response = await fetch(`${apiBase}${url}/`, options);
        return await response.json();
    } catch (error) {
        logDebug('API Error:', error);
    }
    return [];
}

/**
 * Retrieves the CSRF token.
 * @returns {Promise<string>} - A promise that resolves to the CSRF token.
 */
async function getCsrfToken() {
    let _csrfToken = getSessionStorageOrDefault('csrfToken', null);
    if (_csrfToken === null) {
        try {
            const response = await fetch(`${apiBase}/csrf/`, {
                credentials: 'include',
            });
            const data = await response.json();
            _csrfToken = data.csrfToken;
            sessionStorage.setItem('csrfToken', JSON.stringify(_csrfToken));
        } catch (error) {
            logDebug('CSRF Error:', error);
        }
        return _csrfToken;
    }
}

/**
 * Retrieves a value from session storage or returns a default value.
 * @param {string} key - The key to retrieve from session storage.
 * @param {any} defaultValue - The default value to return if the key is not found in session storage.
 * @returns {any} - The value retrieved from session storage or the default value.
 */
function getSessionStorageOrDefault(key, defaultValue) {
    const stored = sessionStorage.getItem(key);
    if (!stored) {
        return defaultValue;
    }
    return JSON.parse(stored);
}

export { apiCall };
