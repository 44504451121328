const EmptyOrder = {
    items: [],
    delivery_date: '',
    delivery_date_id: '',
    amount: 0,
    isCreditCard: false,
    name: '',
    last_name: '',
    email: '',
    payment: null,
    telephone: '',
    date_paid: null,
    card_token_id: null,
};

export default EmptyOrder;
