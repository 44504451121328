import { array, object, string, number } from 'yup';
import { matchIsValidTel } from 'mui-tel-input';
import {
    MAX_CHAR_LIMIT,
    MAX_UNIT_QUANTITY,
    MIN_CHAR_LIMIT,
    MIN_UNIT_QUANTITY,
} from '../lib/limits';

const itemSchema = object({
    id: number().positive().required(),
    name: string().required(),
    price: number().positive().required(),
    quantity: number()
        .positive()
        .min(MIN_UNIT_QUANTITY)
        .max(MAX_UNIT_QUANTITY)
        .required(),
});

const orderSchema = object({
    items: array().of(itemSchema).required(),
    delivery_date: string().datetime().required(),
    delivery_date_id: string().uuid().required(),
    amount: number().positive().required(),
    name: string()
        .matches(/^[aA-zZ\s]+$/)
        .trim()
        .max(MAX_CHAR_LIMIT)
        .min(MIN_CHAR_LIMIT)
        .required(),
    last_name: string()
        .matches(/^[aA-zZ\s]+$/)
        .trim()
        .max(MAX_CHAR_LIMIT)
        .min(MIN_CHAR_LIMIT)
        .required(),
    email: string()
        .email()
        .trim()
        .test(
            'fullmail',
            'Email with TLD',
            (val) => val.split('@')[1]?.split('.').length > 1,
        )
        .required(),
    telephone: string()
        .trim()
        .test((val) => matchIsValidTel(val))
        .required(),
});

export { orderSchema };
