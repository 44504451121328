import React from 'react';
import OrderDetail from '../components/OrderDetail';
import Menu from '../components/Menu';
import MercadoPagoComponent from '../components/MercadoPagoComponent';
import ClipComponent from '../components/ClipComponent';

const PAYMENT_GATEWAY = process.env.REACT_APP_PAYMENT_GATEWAY;
const steps = ['Carrito', 'Pedido', 'Pago'];

function getStepContent(step, props) {
    switch (step) {
        case 0:
            return (
                <Menu
                    menu={props.menu}
                    order={props.order}
                    setOrder={props.setOrder}
                />
            );
        case 1:
            return (
                <OrderDetail
                    order={props.order}
                    setOrder={props.setOrder}
                    setLoading={props.setLoading}
                />
            );
        case 2:
            if (PAYMENT_GATEWAY === 'clip') {
                return (
                    <ClipComponent
                        order={props.order}
                        activeStep={props.activeStep}
                        setActiveStep={props.setActiveStep}
                        setOrder={props.setOrder}
                        setLoading={props.setLoading}
                    />
                );
            } else {
                return (
                    <MercadoPagoComponent
                        order={props.order}
                        activeStep={props.activeStep}
                        setActiveStep={props.setActiveStep}
                        setOrder={props.setOrder}
                        setLoading={props.setLoading}
                    />
                );
            }
        default:
            throw new Error('The step does not exist');
    }
}

export { steps, getStepContent, PAYMENT_GATEWAY };
