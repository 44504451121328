import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Copyright from './Copyright';
import EmptyOrder from '../helpers/emptyOrder';
import Review from './Review';
import { RichfoodsHeader } from './RichfoodsHeader';
import { steps, getStepContent } from '../helpers/stepperHelper';
import { orderSchema } from '../lib/schemas';
import { logDebug } from '../lib/logger';
import PickupNotice from './PickupNotice';
import { useTranslation } from 'react-i18next';

export default function Checkout({ menu, loading, setLoading }) {
    const [activeStep, setActiveStep] = useState(0);
    const [order, setOrder] = useState(EmptyOrder);
    const [isNextStepDisabled, disableNextStep] = useState(false);
    const { t } = useTranslation();
    useEffect(() => {
        switch (activeStep) {
            case 0:
                disableNextStep(!order.amount > 0);
                break;
            case 1:
                disableNextStep(() => {
                    return !orderSchema.isValidSync(order);
                });
                break;
            default:
                break;
        }
    }, [activeStep, isNextStepDisabled, order]);

    logDebug('orderData', order);

    const handleNext = async () => {
        disableNextStep(true);
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const componentProperties = {
        order: order,
        setOrder: setOrder,
        menu: menu,
        activeStep: activeStep,
        setActiveStep: setActiveStep,
        enableNextStep: disableNextStep,
        setLoading: setLoading,
    };

    return (
        <React.Fragment>
            <CssBaseline />
            <RichfoodsHeader
                order={order}
                setActiveStep={setActiveStep}
                loading={loading}
            />
            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                <PickupNotice />
                <Paper
                    sx={{
                        minHeight: '360px',
                        minWidth: '100%',
                        maxWidth: '100%',
                        my: { xs: 3, md: 6 },
                        p: { xs: 2, md: 3 },
                    }}
                >
                    <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{t(label)}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {activeStep === steps.length ? (
                        <React.Fragment>
                            <Review order={order} />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {getStepContent(activeStep, componentProperties)}
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                {activeStep !== 0 && (
                                    <Button
                                        onClick={handleBack}
                                        sx={{ mt: 3, ml: 1 }}
                                    >
                                        Atras
                                    </Button>
                                )}
                                {activeStep === steps.length - 1 ? null : (
                                    <Button
                                        variant="contained"
                                        onClick={handleNext}
                                        sx={{ mt: 3, ml: 1 }}
                                        disabled={isNextStepDisabled}
                                    >
                                        {activeStep === steps.length - 1
                                            ? t('Pay')
                                            : t('Next')}
                                    </Button>
                                )}
                            </Box>
                        </React.Fragment>
                    )}
                </Paper>
                <Copyright />
            </Container>
        </React.Fragment>
    );
}
