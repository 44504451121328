import React from 'react';
import Alert from '@mui/material/Alert';
import { withTranslation } from 'react-i18next';

const PickupNotice = ({ t }) => {
    return (
        <Alert severity="info">
            <a
                target="_blank"
                rel="noreferrer"
                href="https://www.google.com/maps/place/Villa+Napoles+135,+Villas+del+Mirador,+66365+Cdad.+Santa+Catarina,+N.L./@25.6922439,-100.4440111,17z/data=!3m1!4b1!4m5!3m4!1s0x866298205a2d1445:0x5a62f7c6f855e486!8m2!3d25.6922391!4d-100.4414308?entry=ttu"
            >
                {`📍🏠`}
            </a>
            {` ${t(
                'All orders are picked up at the address',
            )} [Villa Napoles #135, Villas del Mirador, Santa Catarina `}
            <a
                target="_blank"
                rel="noreferrer"
                href="https://www.google.com/maps/place/Villa+Napoles+135,+Villas+del+Mirador,+66365+Cdad.+Santa+Catarina,+N.L./@25.6922439,-100.4440111,17z/data=!3m1!4b1!4m5!3m4!1s0x866298205a2d1445:0x5a62f7c6f855e486!8m2!3d25.6922391!4d-100.4414308?entry=ttu"
            >
                {`Ubicación 📍🏠️]`}
            </a>
        </Alert>
    );
};

export default withTranslation()(PickupNotice);
