import React from 'react';
import { initMercadoPago, CardPayment } from '@mercadopago/sdk-react';
import mapPaymentData from '../helpers/paymentHelper';
import { apiCall } from '../helpers/apiHelper';
import Review from './Review';
import { orderSchema } from '../lib/schemas';
import Box from '@mui/material/FormControl';
import { PROCESS_PAYMENT } from '../lib/api';
import { logDebug } from '../lib/logger';

initMercadoPago(process.env.REACT_APP_MP_PUBLIC_KEY, {
    locale: 'es-MX',
    advancedFraudPrevention: true,
});

const MercadoPagoComponent = ({
    order,
    activeStep,
    setActiveStep,
    setOrder,
    setLoading,
}) => {
    const { initialization, customization } = mapPaymentData(order);

    const onSubmit = async (formData) => {
        // callback llamado al hacer click en el botón enviar datos
        const payload = {
            ...order,
            ...{
                payment: {
                    ...formData,
                    ...{ name: `${order.name} ${order.last_name}` },
                },
            },
        };

        const validation = await orderSchema.isValid(order);

        logDebug('orderSchema.isValid', validation);

        if (validation) {
            logDebug('PROCESS_PAYMENT request', payload);
            return new Promise((resolve, reject) => {
                setLoading(true);
                apiCall(PROCESS_PAYMENT, 'POST', payload)
                    .then((response) => {
                        setLoading(false);
                        logDebug('PROCESS_PAYMENT response', response);
                        if (response.error) {
                            logDebug('payment error', response.error);
                        }
                        setOrder({
                            ...order,
                            ...{
                                order_id: response.order_id,
                                date_received: response.order_received,
                                date_paid: response.order_paid,
                                payment_status: response.payment.status,
                                payment_status_detail:
                                    response.payment.status_detail,
                            },
                        });
                        if (
                            response.payment.status === 'approved' ||
                            response.payment.status === 'in_process'
                        ) {
                            setActiveStep(activeStep + 1);
                            resolve();
                        }
                        reject();
                    })
                    .catch((error) => {
                        // manejar la respuesta de error al intentar crear el pago
                        setLoading(false);
                        onError(error);
                        reject();
                    });
            });
        }
    };

    const onError = async (error) => {
        // callback llamado para todos los casos de error de Brick
        logDebug('onError', error);
    };

    const onReady = async () => {
        /*
      Callback llamado cuando Brick está listo.
      Aquí puedes ocultar cargamentos de su sitio, por ejemplo.
    */
    };

    logDebug('MercadoPago', order);

    return (
        <Box sx={{ minHeight: '552px' }}>
            <Review order={order} />
            <CardPayment
                initialization={initialization}
                customization={customization}
                onSubmit={onSubmit}
                onReady={onReady}
                onError={onError}
            />
        </Box>
    );
};

export default MercadoPagoComponent;
