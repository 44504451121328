import React, { useEffect, useRef, useState } from 'react';
import Review from './Review';
import Box from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { PROCESS_PAYMENT } from '../lib/api';
import { logDebug } from '../lib/logger';
import { orderSchema } from '../lib/schemas';

import { apiCall } from '../helpers/apiHelper';
import { pendingActionHandler } from '../helpers/pendingActionHelper';

const ClipComponent = ({
    order,
    activeStep,
    setActiveStep,
    setOrder,
    setLoading,
}) => {
    const [mounted, setMounted] = useState(false);
    const [card, setCard] = useState(null);

    const ref = useRef(null);
    useEffect(() => {
        if (!mounted && ref) {
            setMounted(true);
            // TODO review if temporary fn is required.
            const fn = () => {
                const temp =
                    document.getElementById('checkout').childElementCount;
                logDebug('div#checkout children?', temp);
                if (temp < 1) {
                    // clipCard isn't available at compile time, its loaded
                    // from the web on runtime
                    // eslint-disable-next-line
                    const c = clipCard();
                    c.mount('checkout');
                    setCard(c);
                }
            };
            fn();
        }
    }, [mounted, setMounted, ref]);

    const onSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        let cardToken = null;
        try {
            // Obtén el token de la tarjeta
            cardToken = await card.cardToken();
            logDebug('card.cardToken', cardToken);
            const updated_order = {
                ...order,
                ...{ card_token_id: cardToken.id },
            };
            const validation = await orderSchema.isValid(updated_order);
            logDebug('orderSchema.isValid', validation);
            if (validation) {
                setOrder(updated_order);
                const payload = {
                    ...updated_order,
                    ...{
                        payment: {
                            ...{ name: `${order.name} ${order.last_name}` },
                        },
                    },
                };
                logDebug('PROCESS_PAYMENT request', payload);
                return new Promise((resolve, reject) => {
                    setLoading(true);
                    apiCall(PROCESS_PAYMENT, 'POST', payload)
                        .then((response) => {
                            setLoading(false);
                            logDebug('PROCESS_PAYMENT response', response);
                            const { payment } = response;
                            if (payment.error_code) {
                                logDebug('payment error'); // TODO handle payment error
                                setOrder({
                                    ...updated_order,
                                    ...{
                                        payment_status: 'error',
                                        payment_status_detail:
                                            payment.status_detail,
                                    },
                                });
                            }

                            switch (payment.status) {
                                case 'rejected':
                                    setOrder({
                                        ...updated_order,
                                        ...{
                                            payment_status: payment.status,
                                            payment_status_detail:
                                                payment.status_detail,
                                        },
                                    });
                                    break;
                                case 'approved':
                                    setOrder({
                                        ...updated_order,
                                        ...{
                                            payment_status: payment.status,
                                            payment_status_detail:
                                                payment.status_detail,
                                        },
                                    });
                                    setActiveStep(activeStep + 1);
                                    break;
                                case 'pending':
                                    pendingActionHandler(
                                        payment,
                                        setOrder,
                                        updated_order,
                                    );
                                    break;
                                default:
                                    logDebug(payment, order);
                                    break;
                            }
                            resolve();
                        })
                        .catch((error) => {
                            // TODO manejar la respuesta de error al intentar crear el pago
                            logDebug('apiCall PROCESS_PAYMENT', error); // TODO handle 500 error
                            setLoading(false);
                            resolve();
                        });
                });
            }
        } catch (error) {
            logDebug('onSubmit error', error);
            // TODO Maneja errores durante la tokenización de la tarjeta
            switch (error.code) {
                case 'CL2200':
                case 'CL2290':
                    alert('Error: ' + error.message);
                    throw error;
                case 'AI1300':
                    logDebug('Error: ', error.message);
                    break;
                default:
                    break;
            }
        } finally {
            setLoading(false);
        }
    };
    return (
        <Box sx={{ minHeight: '520px', width: '100%', overflow: 'hidden' }}>
            <Review order={order} />
            <form id="payment-form">
                <div id="checkout" ref={ref}></div>
                <Button variant="contained" id="submit" onClick={onSubmit}>
                    Pagar
                </Button>
                <p id="cardTokenId"></p>
            </form>
        </Box>
    );
};
export default ClipComponent;
