import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/FormControl';
import { isEmpty } from 'lodash';
import { fetchDeliveryDates, formatDate } from '../helpers/orderDetailHelper';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import { logDebug } from '../lib/logger';
import { displayOrderStatus } from '../helpers/orderDetailHelper';

export default function OrderDetail({ setOrder, order, setLoading }) {
    const [deliveryDates, setDeliveryDates] = useState([]);
    const [telephone, setTelephone] = useState(order.telephone);
    const [error, setError] = useState({
        name: false,
        last_name: false,
        delivery_date: false,
        telephone: false,
        email: false,
    });
    useEffect(() => {
        setLoading(true);
        fetchDeliveryDates()
            .then((response) => {
                setLoading(false);
                setDeliveryDates(response);
            })
            .catch(() => setLoading(false));
    }, [setLoading]);

    logDebug('AddressForm', order);

    const handleState = (e) => {
        const item = {};
        item[e.target.name] = e.target.value;
        setOrder({ ...order, ...item });
    };
    const handleDdateChange = (e) => {
        const delivery_date = deliveryDates.find(
            (delivery) => delivery.id === e.target.value,
        );
        setOrder({
            ...order,
            ...{
                delivery_date_id: e.target.value,
                delivery_date: delivery_date.slot,
            },
        });
    };
    const handlePhoneChange = (value) => {
        setOrder({ ...order, ...{ telephone: value } });
        matchIsValidTel(value)
            ? setError({ ...error, ...{ telephone: false } })
            : setError({ ...error, ...{ telephone: true } });
        setTelephone(value);
    };

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Datos del Pedido
            </Typography>
            <Box sx={{ minHeight: '360px' }}>
                {displayOrderStatus(order)}
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="name"
                            name="name"
                            label="Nombre"
                            fullWidth
                            autoComplete="firstName"
                            variant="standard"
                            onChange={handleState}
                            value={order.name}
                            error={error.name}
                            onBlur={() =>
                                isEmpty(order.name)
                                    ? setError({ ...error, ...{ name: true } })
                                    : setError({ ...error, ...{ name: false } })
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="last_name"
                            name="last_name"
                            label="Apellido"
                            fullWidth
                            required
                            autoComplete="lastName"
                            variant="standard"
                            onChange={handleState}
                            value={order.last_name}
                            error={error.last_name}
                            onBlur={() =>
                                isEmpty(order.last_name)
                                    ? setError({
                                          ...error,
                                          ...{ last_name: true },
                                      })
                                    : setError({
                                          ...error,
                                          ...{ last_name: false },
                                      })
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MuiTelInput
                            label="Tel️éfono"
                            required
                            variant="standard"
                            onlyCountries={['MX']}
                            defaultCountry="MX"
                            disableDropdown={true}
                            forceCallingCode
                            onChange={handlePhoneChange}
                            value={telephone}
                            error={error.telephone}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel id="ddate-select-label">
                                Fecha de Entrega *
                            </InputLabel>
                            <Select
                                required={true}
                                labelId="ddate-select-label"
                                id="delivery_date"
                                name="delivery_date"
                                onChange={handleDdateChange}
                                label="Fecha de Entrega"
                                fullWidth
                                value={order.delivery_date_id}
                                error={error.delivery_date}
                                onBlur={() =>
                                    isEmpty(order.delivery_date)
                                        ? setError({
                                              ...error,
                                              ...{ delivery_date: true },
                                          })
                                        : setError({
                                              ...error,
                                              ...{ delivery_date: false },
                                          })
                                }
                            >
                                {!isEmpty(deliveryDates) ? (
                                    deliveryDates.map((item) => {
                                        return (
                                            <MenuItem
                                                key={item.id}
                                                value={item.id}
                                            >
                                                {formatDate(item.slot)}
                                            </MenuItem>
                                        );
                                    })
                                ) : (
                                    <MenuItem key={'-'} value={''}>
                                        {''}
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            id="email"
                            name="email"
                            label="e-mail"
                            fullWidth
                            autoComplete="email"
                            variant="standard"
                            onChange={handleState}
                            value={order.email}
                            error={error.email}
                            onBlur={() =>
                                isEmpty(order.email)
                                    ? setError({ ...error, ...{ email: true } })
                                    : setError({
                                          ...error,
                                          ...{ email: false },
                                      })
                            }
                        />
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    );
}
