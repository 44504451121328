import { MAX_PAYMENT_INSTALLMENTS } from '../lib/limits';

const mapPaymentData = function (order) {
    const initialization = {
        amount: order.amount,
        payer: {
            email: order.email,
            firstName: order.name,
            lastName: order.last_name,
        },
    };
    const customization = {
        visual: {
            hideFormTitle: false,
            hidePaymentButton: false,
            style: {
                theme: 'default', // default, dark, flat, bootstrap
            },
        },
        paymentMethods: {
            maxInstallments: MAX_PAYMENT_INSTALLMENTS,
        },
    };
    return { customization: customization, initialization: initialization };
};

const payment_statuses = {
    approved: {
        status_detail: 'accredited',
        Descripción:
            '¡Listo! Se acreditó tu pago. En tu resumen verás el cargo de amount como statement_descriptor.',
    },
    in_process: {
        pending_contingency: {
            Descripción:
                'Estamos procesando tu pago. No te preocupes, menos de 2 días hábiles te avisaremos por e-mail si se acreditó.',
        },
        pending_review_manual: {
            Descripción:
                'Estamos procesando tu pago. No te preocupes, menos de 2 días hábiles te avisaremos por e-mail si se acreditó o si necesitamos más información.',
        },
    },
    rejected: {
        cc_rejected_bad_filled_card_number: {
            Descripción: 'Revisa el número de tarjeta.',
        },
        cc_rejected_bad_filled_date: {
            Descripción: 'Revisa la fecha de vencimiento.',
        },
        cc_rejected_bad_filled_other: {
            Descripción: 'Revisa los datos.',
        },
        cc_rejected_bad_filled_security_code: {
            Descripción: 'Revisa el código de seguridad de la tarjeta.',
        },
        cc_rejected_blacklist: {
            Descripción: 'No pudimos procesar tu pago.',
        },
        cc_rejected_call_for_authorize: {
            Descripción:
                'Debes autorizar ante payment_method_id el pago de amount.',
        },
        cc_rejected_card_disabled: {
            Descripción:
                'Llama a payment_method_id para activar tu tarjeta o usa otro medio de pago. El teléfono está al dorso de tu tarjeta.',
        },
        cc_rejected_card_error: {
            Descripción: 'No pudimos procesar tu pago.',
        },
        cc_rejected_duplicated_payment: {
            Descripción:
                'Ya hiciste un pago por ese valor. Si necesitas volver a pagar usa otra tarjeta u otro medio de pago.',
        },
        cc_rejected_high_risk: {
            Descripción:
                'Tu pago fue rechazado. Elige otro de los medios de pago, te recomendamos con medios en efectivo.',
        },
        cc_rejected_insufficient_amount: {
            Descripción: 'Tu payment_method_id no tiene fondos suficientes.',
        },
        cc_rejected_invalid_installments: {
            Descripción:
                'payment_method_id no procesa pagos en installments cuotas.',
        },
        cc_rejected_max_attempts: {
            Descripción:
                'Llegaste al límite de intentos permitidos. Elige otra tarjeta u otro medio de pago.',
        },
        cc_rejected_other_reason: {
            Descripción: 'payment_method_id no procesó el pago.',
        },
    },
};

export default mapPaymentData;
export { payment_statuses };
