import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

function Copyright() {
    return (
        <Typography variant="body2" color="primary" align="center">
            {'Copyright © '}
            <Link href={process.env.REACT_APP_BRAND_WEBSITE}>
                {process.env.REACT_APP_BRAND_NAME}
            </Link>{' '}
            {new Date().getFullYear()}
            {' - Todos los derechos reservados.'}
        </Typography>
    );
}

export default Copyright;
