import { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import LinearProgress from '@mui/material/LinearProgress';

const logo = process.env.PUBLIC_URL + '/logo32x32.png';

export function RichfoodsHeader({ order, setActiveStep, loading }) {
    const [items, setItems] = useState(() => {
        let t = 0;
        order.items.forEach((item) => {
            if (!isNaN(item.quantity)) {
                t += item.quantity;
            }
        });
        return t;
    });
    useEffect(() => {
        setItems(() => {
            let t = 0;
            order.items.forEach((item) => {
                if (!isNaN(item.quantity)) {
                    t += item.quantity;
                }
            });
            return t;
        });
    }, [order]);
    return (
        <AppBar
            position="static"
            color="default"
            sx={{ backgroundColor: 'secondary.main' }}
        >
            <Toolbar>
                <IconButton href="https://app.richfoods.mx" sx={{ p: 0 }}>
                    <Avatar
                        alt={`${process.env.REACT_APP_BRAND_NAME} logo`}
                        src={logo}
                    />
                </IconButton>
                <Typography
                    variant="h6"
                    noWrap
                    component="a"
                    href="https://app.richfoods.mx"
                    sx={{
                        mr: 2,
                        ml: 2,
                        fontWeight: 700,
                        color: 'white',
                        textDecoration: 'none',
                        textShadow: '1px 1px 2px black',
                    }}
                >
                    {`${process.env.REACT_APP_BRAND_NAME}`}
                </Typography>
                <Box sx={{ flexGrow: 6 }}>{` `}</Box>
                <Box sx={{ flexGrow: 1 }}>
                    {' '}
                    <IconButton
                        sx={{ color: 'white' }}
                        aria-label={`comidas ${items} en el carrito`}
                        onClick={() => setActiveStep(0)}
                    >
                        <Badge badgeContent={items} color="error">
                            <ShoppingCartIcon
                                badgeContent={items}
                                color="white"
                            />
                        </Badge>
                    </IconButton>
                </Box>
            </Toolbar>
            {loading ? (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress color="primary" />
                </Box>
            ) : null}
        </AppBar>
    );
}
