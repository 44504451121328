import Checkout from './components/Checkout';
import { apiCall } from './helpers/apiHelper';
import { useEffect, useState } from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ThemeProvider } from '@mui/material';
import theme from './theme';
import { PRODUCT } from './lib/api';
import CookiesNotice from './components/CookiesNotice';

function App() {
    const [menu, setMenu] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        const fetchProducts = async () => {
            setLoading(true);
            const products = await apiCall(PRODUCT, 'GET');
            setMenu(products);
            setLoading(false);
        };

        fetchProducts();
        const bannerDismissed = localStorage.getItem('cookiesBannerDismissed');
        if (!bannerDismissed) {
            setShowBanner(true);
        }
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                <Checkout
                    menu={menu}
                    loading={loading}
                    setLoading={setLoading}
                />
                {showBanner && <CookiesNotice setShowBanner={setShowBanner} />}
            </div>
        </ThemeProvider>
    );
}

export default App;
