import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { withTranslation } from 'react-i18next';

const CookiesNotice = ({ setShowBanner, t }) => {
    const handleDismissBanner = () => {
        localStorage.setItem('cookiesBannerDismissed', 'true');
        setShowBanner(false);
    };
    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 0,
                width: '100%',
                backgroundColor: 'primary.main',
                color: 'white',
                p: 2,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                zIndex: 1000,
            }}
        >
            <Typography variant="body1">
                {t(
                    `We use cookies to improve your experience on our site. By using our site, you accept our use of cookies.`,
                )}
            </Typography>
            <Button
                variant="contained"
                color="secondary"
                onClick={handleDismissBanner}
            >
                {t('Dismiss')}
            </Button>
        </Box>
    );
};

export default withTranslation()(CookiesNotice);
