import React, { useEffect } from 'react';
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import { isEmpty, isUndefined } from 'lodash';
import { formatDate } from '../helpers/orderDetailHelper';
import { displayOrderStatus } from '../helpers/orderDetailHelper';
import PendingActionModal from './PendingActionModal';

export default function Review({ order }) {
    const [open, setOpen] = useState(order.payment_pending_url ? true : false);
    useEffect(() => {
        setOpen(true);
    }, []);
    const { items } = order;

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Resumen de la Orden
            </Typography>
            <List disablePadding>
                {!isEmpty(items)
                    ? items.map((item) => {
                          return (
                              <ListItem key={item.id} sx={{ py: 1, px: 0 }}>
                                  <ListItemText
                                      primary={item.name}
                                      secondary={`$${item.price} x ${item.quantity}`}
                                  />
                                  <Typography variant="body2">{`$ ${
                                      Number(item.price) * Number(item.quantity)
                                  }`}</Typography>
                              </ListItem>
                          );
                      })
                    : null}
                <ListItem sx={{ py: 1, px: 0 }}>
                    <ListItemText primary="Total" />
                    <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                        {`$ ${order.amount}`}
                    </Typography>
                </ListItem>
            </List>

            <Grid container spacing={2} sx={{ minWidth: '100%' }}>
                <Grid item xs={12} sm={6}>
                    {displayOrderStatus(order)}
                    {!isUndefined(order.order_id) && (
                        <Typography gutterBottom>
                            {`Orden: ${order.order_id}`}
                        </Typography>
                    )}
                    <Typography gutterBottom>
                        {`Entrega: ${formatDate(order.delivery_date)}`}
                    </Typography>
                    <Typography
                        gutterBottom
                    >{`${order.name} ${order.last_name}`}</Typography>
                    <Typography gutterBottom>{`${order.telephone}`}</Typography>
                </Grid>
            </Grid>
            <PendingActionModal
                open={open}
                setOpen={setOpen}
                url={order.payment_pending_url}
            />
        </React.Fragment>
    );
}
