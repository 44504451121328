const clipCodes = {
    approved: [
        { code: 'AP-PAI01', message: 'Paid' },
        { code: 'AP-REF01', message: 'Partially refunded' },
    ],
    refunded: [{ code: 'RE-REF01', message: 'Refunded' }],
    authorized: [{ code: 'AU-CAP01', message: 'Pending capture' }],
    cancelled: [
        { code: 'CA-AUT01', message: 'Cancelled' },
        { code: 'CA-MAN01', message: 'Cancelled' },
        { code: 'CA-MER01', message: 'Cancelled' },
    ],
    rejected: [
        { code: 'RE-BIN01', message: 'Rejected' },
        { code: 'RE-ERI03', message: 'Rejected' },
        { code: 'RE-3DS01', message: 'Fail 3DS authentication' },
        { code: 'RE-CHI01', message: 'Rejected' },
        { code: 'RE-ISS01', message: 'Not sufficient funds' },
        { code: 'RE-ISS02', message: 'Do not honour' },
        { code: 'RE-ISS03', message: 'Restricted card' },
        { code: 'RE-ISS04', message: 'Reserved for private use' },
        {
            code: 'RE-ISS05',
            message: 'Transaction not permitted to cardholder',
        },
        { code: 'RE-ISS06', message: 'Pick-up card' },
        { code: 'RE-ISS07', message: 'Expired card' },
        { code: 'RE-ISS08', message: 'Exceeds withdrawal amount limit' },
        { code: 'RE-ISS09', message: 'Invalid pin (one time)' },
        { code: 'RE-ISS10', message: 'Allowable number of pin tries exceeded' },
        { code: 'RE-ISS11', message: 'Refer to card issuer' },
        { code: 'RE-ISS12', message: 'Invalid amount' },
        {
            code: 'RE-ISS13',
            message: 'Destination not available (Issuer offline)',
        },
        { code: 'RE-ISS14', message: 'Issuer or switch is inoperative' },
        { code: 'RE-ISS15', message: 'Visa/mc fallback' },
        { code: 'RE-ISS16', message: 'Invalid card number (no such number)' },
        { code: 'RE-ISS17', message: 'Invalid merchant' },
        { code: 'RE-ISS18', message: 'Invalid transaction' },
        { code: 'RE-ISS19', message: 'Rejected' },
        { code: 'RE-ISS20', message: 'Rejected' },
        { code: 'RE-ISS99', message: 'Generic error' },
    ],
    pending: [
        { code: 'PE-EMV01', message: 'Waiting emv' },
        { code: 'PE-SIG01', message: 'Waiting signature' },
        { code: 'PE-3DS01', message: 'Waiting 3DS' },
        { code: 'PE-TIC01', message: 'Waiting payment' },
    ],
};

export { clipCodes };
