import React from 'react';
import { isEmpty } from 'lodash';
import FormControl from '@mui/material/FormControl';
import Product from './Product';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { withTranslation } from 'react-i18next';

function Menu({ menu, order, setOrder, t }) {
    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                {t('Selecciona tus Productos')}
            </Typography>
            <FormControl sx={{ minHeight: '360px' }}>
                <Table
                    aria-label="menu"
                    sx={{ tableLayout: 'fixed', width: '100%' }}
                >
                    <TableBody>
                        {!isEmpty(menu) ? (
                            menu.map((item) => {
                                return (
                                    <Product
                                        key={item.name}
                                        item={item}
                                        order={order}
                                        setOrder={setOrder}
                                    />
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell height={'340px'}>{` `}</TableCell>
                            </TableRow>
                        )}
                        <TableRow>
                            <TableCell align={'right'}>
                                <Typography variant="subtitle1">
                                    {`${t('Total')}:`}
                                </Typography>
                            </TableCell>
                            <TableCell align={'right'}>
                                <Typography variant="subtitle1">{`$ ${order.amount}`}</Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </FormControl>
        </React.Fragment>
    );
}

export default withTranslation()(Menu);
