import React from 'react';
import Alert from '@mui/material/Alert';
import { orderStatusStyle } from '../lib/styles';

const DisplayWarning = () => {
    return (
        <Alert severity="warning" sx={orderStatusStyle}>
            {`Estamos procesando tu orden. Te notificaremos el estado de tu compra.`}
        </Alert>
    );
};

export default DisplayWarning;
