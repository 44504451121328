import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
    palette: {
        primary: {
            main: process.env.REACT_APP_BRAND_PRIMARY_COLOR,
        },
        secondary: {
            main: process.env.REACT_APP_BRAND_SECONDARY_COLOR,
        },
        error: {
            main: red.A400,
        },
    },
    stepper: {
        iconColor: process.env.REACT_APP_BRAND_SECONDARY_COLOR, // or logic to change color
    },
    typography: {
        fontFamily: process.env.REACT_APP_BRAND_FONT_FAMILY,
    },
});

export default theme;
