/**
 * Helper functions for managing order details.
 * @module orderDetailHelper
 */
import { apiCall } from './apiHelper';
import { isEmpty } from 'lodash';
import { DELIVERY_DATE } from '../lib/api';
import { DisplayOrderStatusMercadopago } from '../components/DisplayOrderStatusMercadopago';
import { DisplayOrderStatusClip } from '../components/DisplayOrderStatusClip';
import { PAYMENT_GATEWAY } from './stepperHelper';
import { Box } from '@mui/material';
import { orderSchema } from '../lib/schemas';

/**
 * Fetches delivery dates from the API.
 * @async
 * @function fetchDeliveryDates
 * @returns {Promise} - A promise that resolves to the delivery dates.
 */
async function fetchDeliveryDates() {
    return await apiCall(DELIVERY_DATE, 'GET');
}

/**
 * Formats a date object into a localized string.
 * @function formatDate
 * @param {Object} obj - The date object to format.
 * @returns {string|null} - The formatted date string or null if the object is empty.
 */
const formatDate = (obj) => {
    if (isEmpty(obj)) {
        return null;
    }
    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };
    return new Date(obj).toLocaleDateString('es-MX', options);
};

/**
 * Displays the order status based on the payment gateway.
 * @function displayOrderStatus
 * @param {Object} order - The order object.
 * @returns {JSX.Element} - The JSX element representing the order status.
 */ const displayOrderStatus = (order) => {
    return PAYMENT_GATEWAY === 'clip' ? (
        <Box sx={{ width: '100%', overflow: 'hidden' }}>
            <DisplayOrderStatusClip order={order} />
        </Box>
    ) : (
        <Box sx={{ width: '100%', overflow: 'hidden' }}>
            <DisplayOrderStatusMercadopago order={order} />
        </Box>
    );
};

export { fetchDeliveryDates, formatDate, orderSchema, displayOrderStatus };
